<template>
  <SlideInFromLeftTransition>
    <nav
      v-if="isSideNavigationOpen"
      class="fixed inset-0 z-[51] h-full overflow-hidden overflow-y-auto bg-white"
      :class="{ 'animate-pulse': fetching }"
    >
      <div
        class="relative h-full"
        :style="{ 'max-height': 'calc(100% - 53px)' }"
      >
        <div
          class="fixed top-0 z-20 flex w-full items-center justify-between border-b border-gray-300 bg-white px-4 py-1"
        >
          <FimButton
            type="link"
            class="pl-0"
            data-testid="close-mobile-side-navigation"
            @click="closeSideNavigation"
          >
            <template #icon>
              <LazyIconFielmannClose class="size-6" />
            </template>
          </FimButton>
          <div class="flex justify-end">
            <a :href="isLoggedIn ? '/account' : '/signin'">
              <template v-if="isLoggedIn">
                <span
                  class="border-b border-solid border-gray-500 align-middle"
                  >{{ $t('myaccount.fielmann_account') }}</span
                >
                <span class="ml-3">
                  <LazyIconFielmannUserVerified
                    class="inline-block size-6 stroke-[1.5]"
                  />
                </span>
              </template>
              <template v-else>
                <span
                  class="border-b border-solid border-gray-500 align-middle"
                  >{{ $t('myaccount.fielmann_account') }}</span
                >
                <span class="ml-2">
                  <LazyIconFielmannUser
                    class="inline-block size-6 stroke-[1.5]"
                  />
                </span>
              </template>
            </a>
          </div>
        </div>
        <div class="relative mt-[69px]">
          <SideNavigation
            class="pb-20"
            :navigation-tree="navigationTree"
            :fetching="fetching"
            @click:item="itemClick"
          />
        </div>
      </div>
    </nav>
  </SlideInFromLeftTransition>
</template>

<script setup lang="ts">
import type { NavigationLink } from '~/rpcMethods/navigation'

defineProps({
  subCategories: {
    type: [Array, Object],
    default: () => [],
  },
  navigationTree: {
    type: Array as PropType<NavigationLink[]>,
    default: () => [],
  },
  fetching: {
    type: Boolean,
    required: true,
  },
})

// const { closeSideNavigation, isSideNavigationOpen } = useUiState()

const {
  closeSideNavigation,
  isSideNavigationOpen,
  // isSideNavigationActive,
  // setSideNavigationActiveState,
} = useSideNavigation()

const { isLoggedIn } = await useUser({ immediate: false, lazy: true })
const { trackContentView } = await useTrackingEvents()

const route = useRoute()
watch(isSideNavigationOpen, () => {
  trackContentView({
    eventName: 'FielmannBasic_MenuIconClick',
    contentName: route.fullPath,
    title: document?.title || '',
    pageType: 'off_canvas',
    pageTypeId: route.params.pathMatch?.toString() ?? '',
    clickOrigin: route.fullPath,
  })
})

const itemClick = (event: Event) => {
  closeSideNavigation()
  if (!event?.target) {
    return
  }
  const { outerText, id } = event.target as any
  trackContentView({
    eventName: 'FielmannBasic_MenuItemClick',
    contentName: route.fullPath,
    title: document?.title || '',
    pageType: 'off_canvas',
    pageTypeId: route.params.pathMatch?.toString() ?? '',
    clickOrigin: route.fullPath,
    element: { id, label: outerText?.trim() },
  })
}
</script>
